import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Select",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Select`}</strong>{` is used as a replacement of the native select element. It is used for
collecting user provided information from a list of options.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`() => {

    const [value, setValue] = React.useState( null );

    return (
        <div style={{ width: "200px" }}>
           <Select
              onChange={setValue}
              value={value}
              placeholder="Choose document"
              stretch
              searchable
              searchPlaceholder="Search"
              label="Document"
              options={[
                { id: "1", label: "First"},
                { id: "2", label: "Second"},
                { id: "3", label: "Third"},
                { id: "4", label: "Fourth"},
                { id: "5", label: "Fifth"},
                { id: "6", label: "Sixth"}
              ]} />
        </div>
    )
}
`}</code></pre>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` accepts an `}<inlineCode parentName="p">{`options`}</inlineCode>{` prop which define an option that is available to
be selected by the user. The options can have an `}<inlineCode parentName="p">{`id`}</inlineCode>{` and a `}<inlineCode parentName="p">{`label`}</inlineCode>{` properties, which
correspond to the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop, and the label that will be rendered to the user.`}</p>
    <h2>{`Interaction`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` is an uncontrolled component by default. Use the `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`, `}<inlineCode parentName="p">{`value`}</inlineCode>{` props
to define the user interaction behavior for the component.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<em parentName="p">{`value`}</em>{` prop accepts only a value that has been defined in the `}<em parentName="p">{`options`}</em>{` prop.`}</p>
    </blockquote>
    <h2>{`Search`}</h2>
    <p>{`To enable a search box inside the dropdown menu, please use the `}<inlineCode parentName="p">{`searchable`}</inlineCode>{` prop. To
define the search interaction there are additional props `}<inlineCode parentName="p">{`searchValue`}</inlineCode>{`, `}<inlineCode parentName="p">{`onSearchChange`}</inlineCode>{`
and the `}<inlineCode parentName="p">{`searchPlaceholder`}</inlineCode>{` for whenever the input is empty, as well as `}<inlineCode parentName="p">{`searchLoading`}</inlineCode>{`
that will render the search box in a loading state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`() => {

    const defaultOptions = [
      { id: "1", label: "First"},
      { id: "2", label: "Second"},
      { id: "3", label: "Third"},
      { id: "4", label: "Fourth"},
      { id: "5", label: "Fifth"},
      { id: "6", label: "Sixth"}
    ]

    const [value, setValue] = React.useState( null );
    const [searchValue, setSearchValue] = React.useState( "" );
    const [searchLoading, setSearchLoading] = React.useState( false );
    const [options, setOptions] = React.useState( defaultOptions );
    const handleSearchChange = React.useCallback( searchValue => {
        const selectedOption = defaultOptions.filter( option => option.id === value );
        setSearchValue( searchValue );
        setOptions( selectedOption );
        setSearchLoading( true );
        const timer = setTimeout( () => {
            setOptions(
                selectedOption.concat(
                  defaultOptions
                   .filter( ( { label } ) => label.toLowerCase().includes( searchValue.toLowerCase() ) )
                   .filter( ( { id } ) => id !== value )
                )
            );
            setSearchLoading( false );
        }, 1000);
        return () => clearTimeout( timer );
    }, [value]);

    return (
        <div style={{ width: "200px" }}>
           <Select
              onChange={setValue}
              value={value}
              placeholder="Choose document"
              stretch
              searchable
              searchPlaceholder="Search"
              searchValue={searchValue}
              searchLoading={searchLoading}
              onSearchChange={handleSearchChange}
              options={options} />
        </div>
    )

}
`}</code></pre>
    <h2>{`States`}</h2>
    <h3>{`Loading`}</h3>
    <p><strong parentName="p">{`Select`}</strong>{` can also be rendered in a `}<em parentName="p">{`loading`}</em>{` state, by using the `}<em parentName="p">{`loading`}</em>{` prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
    <Select
      value={"1"}
      loading
      stretch
      options={[
          { id: "1", label: "John Doe" }
      ]} />
</div>
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <p><strong parentName="p">{`Select`}</strong>{` can also be disabled with a prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
    <Select
      value={"1"}
      disabled
      stretch
      options={[
          { id: "1", label: "John Doe" }
      ]} />
</div>
`}</code></pre>
    <h3>{`Modified`}</h3>
    <p><strong parentName="p">{`Select`}</strong>{` can also be in state modified with a prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
    <Select
      value={"1"}
      modified
      stretch
      options={[
          { id: "1", label: "John Doe" }
      ]} />
</div>
`}</code></pre>
    <h2>{`Label`}</h2>
    <p><strong parentName="p">{`Select`}</strong>{` accepts a `}<inlineCode parentName="p">{`label`}</inlineCode>{` prop as well, which will render the select with an
appropriate label`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
    <Select
      value={"1"}
      stretch
      label="Name"
      helper="First and last"
      options={[
          { id: "1", label: "John Doe" },
          { id: "2", label: "Doe Johnson" }
      ]} />
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label rendered with the select field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`helper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rendered on the right of the label ( with `}<em parentName="td">{`label`}</em>{` prop only )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disallows any interaction of the select`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`loading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renders a loader on the right side of the select and disables the field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shows an orange border around`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stretch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will render the select component, filling it's parent container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`searchable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renders additional search text input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`searchValue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value of the search input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`searchPlaceholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text rendered when the search input field is empty`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`searchLoading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renders a loader in the search field ( use with async data )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onSearchChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`( value : string ) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event callback on a change in the search input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Array<{ id: string, label: string }>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The options available to the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`( value : string ) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback when an option has been selected`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      